import { uniq, flatMap, isArray, snakeCase, omit, compact, mapKeys, startsWith } from 'lodash'
import { normalizedUSPhone } from '@/utils/utils'

export default ({ store }) => {
  const KLAVIYO_EVENT_KEY_MAP = {
    '40kStepNuxtEvent': '40k Stepped',
    'AddPaymentInfoNuxtEvent': 'Added Payment Info',
    'AddShippingInfoNuxtEvent': 'Added Shipping Info',
    'AddToCartNuxtEvent': 'Added to Cart',
    'BeginCheckoutNuxtEvent': 'Started Checkout',
    'RemoveFromCartNuxtEvent': 'Removed from Cart',
    'SearchNuxtEvent': 'Searched',
    'SelectItemNuxtEvent': 'Selected Product',
    'SelectPromotionNuxtEvent': 'Selected Promotion',
    'SignupNuxtEvent': 'Signed Up',
    'TradeDesignerAppliedNuxtEvent': 'Trade Designer Applied',
    'ViewCartNuxtEvent': 'Viewed Cart',
    'ViewItemListNuxtEvent': 'Viewed Listing',
    'ViewItemNuxtEvent': 'Viewed Product'
  }
  const KLAVIYO_EXCLUDED_KEYS = [
    'EmailNuxt',
    'event',
    'EventIdNuxt',
    'FirstNameNuxt',
    'LastNameNuxt',
    'PhoneNuxt',
    'UserDataNuxt',
    'ValueNuxt'
  ]

  function getEventName (eventData) {
    const eventName = KLAVIYO_EVENT_KEY_MAP[eventData.event]
    if (eventName !== 'Viewed Listing') return eventName

    if (startsWith(eventData.ItemListIdNuxt, '/categories')) return 'Viewed Category'
    if (startsWith(eventData.ItemListIdNuxt, '/brands')) return 'Viewed Brand'
    if (startsWith(eventData.ItemListIdNuxt, '/collections')) return 'Viewed Collection'

    return eventName
  }

  function handleDataLayerPush (eventData) {
    let data = {
      $event_id: eventData.EventIdNuxt,
      $value: eventData.ValueNuxt,
      ...mapKeys(
        omit(eventData, KLAVIYO_EXCLUDED_KEYS),
        (_value, key) => snakeCase(key.replace(/Nuxt$/, '').replace(/(\w)_(\d)/g, '$1$2'))
      )
    }
    let eventName = getEventName(eventData)

    if (isArray(eventData.ItemsNuxt)) {
      let itemsCategories = flatMap(eventData.ItemsNuxt, item => [item.item_category, item.item_category2, item.item_category3])
          itemsCategories = uniq(itemsCategories)
          itemsCategories = compact(itemsCategories)

      data = {
        ...data,
        items_categories: itemsCategories,
        items_names: compact(eventData.ItemsNuxt.map(item => item.item_name)),
        items_ids: compact(eventData.ItemsNuxt.map(item => item.item_id))
      }
    }
    if (eventName === 'Added to Cart') data = transformAddedToCartData(data)
      console.log(eventName, data)
    klOnsite().push(['track', eventName, data])
  }

  function transformAddedToCartData (data) {
    const item = data.items[0]

    return {
      ...data,
      ...mapKeys(item, (_v, k) => k.startsWith('item_') ? `added_${k}` : `added_item_${k}`)
    }
  }

  function identifyUser () {
    klOnsite().push(['identify', { $email: store.getters['auth/getEmail'] }])
  }

  function setupIdentifyListener () {
    store.subscribe(({ type, payload }, _state) => {
      if (type === 'user/setUserData') {
        if (!payload.email) return

        let { email, first_name, last_name, phone } = payload
        let data = { email, first_name, last_name, phone: normalizedUSPhone(phone) }

        klOnsite().push(['identify', data])
      } else if (type === 'auth/setUser') {
        klOnsite().push(['identify', { email: payload.email }])
      }
    })
  }

  function overrideDataLayer () {
    let dataLayer = window.dataLayer || []
    let dataLayerPush = dataLayer.push

    // Override the push method of the dataLayer array to listen for push events
    dataLayer.push = function () {
      let args = Array.prototype.slice.call(arguments)
      dataLayerPush.apply(window.dataLayer, args)

      let event = args.find(arg => typeof arg === 'object' && KLAVIYO_EVENT_KEY_MAP[arg.event])
      if (!event) return

      handleDataLayerPush(event)
    }
  }

  // We need to create a temporary array in which to store the events that will be sent to Klaviyo when the script is
  // loaded and the user is identified.
  function klOnsite () {
    window._klOnsite = window._klOnsite || []
    return window._klOnsite
  }

  identifyUser()
  setupIdentifyListener()
  overrideDataLayer()
}
